<template>
    <v-navigation-drawer v-model="drawer"
                         :rail="rail"
                         permanent
                         @click="rail = false">

        <v-list-item prepend-icon="mdi-dice-d20-outline"
                     title="Vorlid">
            <template v-slot:append>
                <v-btn variant="text"
                       icon="mdi-chevron-left"
                       @click.stop="rail = !rail"></v-btn>
            </template>
        </v-list-item>
        <v-list nav>
            <v-list-item v-for="item in items" :key="item.title" :prepend-icon="item.icon" :title="item.title" @click.stop="" :to="item.path"></v-list-item>
        </v-list>
    </v-navigation-drawer>

</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        data() {
            return {
                drawer: true,
                rail: true,
                items: [
                    { title: 'Home', icon: 'mdi-home-city', path: '/' },
                    { title: 'Rules', icon: 'mdi-book-open-page-variant', path: 'rules' },
                    //{ title: 'Character', icon: 'mdi-account', path: 'sheet' },
                    { title: 'Abilities', icon: 'mdi-star-plus', path: 'ability' },
                    { title: 'Equipment', icon: 'mdi-sword', path: 'equipment' },
                    { title: 'Spells', icon: 'mdi-auto-fix', path: 'spell' },
                ],
            };
        },
        created() {

        },
        watch: {

        },
        methods: {

        },
    });
</script>