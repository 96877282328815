<template>
  <LandingInformation />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import LandingInformation from '../components/LandingInformation.vue';

export default defineComponent({
  name: 'HomeView',

    components: {
        LandingInformation,
  },
});
</script>
