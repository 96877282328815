<template>
    <div>
        <center>
            <h1>Vorlid</h1>
            <h2>Simplified, easy-to-access RPG</h2>

            <br />
            <br />
            Use the left-hand nav to navigate the site.
            <br />
            <br />
            New? go to the 'Rules' page, where you can learn how to set up your character!
        </center>

    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        data() {
            return {

            };
        },
        created() {

        },
        watch: {

        },
        methods: {

        },
    });
</script>