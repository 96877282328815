import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/sheet',
        name: 'sheet',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "sheet" */ '../views/CharacterSheetView.vue')
    },
    {
        path: '/rules',
        name: 'rules',
        component: () => import(/* webpackChunkName: "rules" */ '../views/RulesView.vue')
    },
    {
        path: '/ability',
        name: 'ability',
        component: () => import(/* webpackChunkName: "ability" */ '../views/AbilityView.vue')
    },
    {
        path: '/equipment',
        name: 'equipment',
        component: () => import(/* webpackChunkName: "equipment" */ '../views/EquipmentView.vue')
    },
    {
        path: '/spell',
        name: 'spell',
        component: () => import(/* webpackChunkName: "spell" */ '../views/SpellView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
